<template>
<div class='stamp'>
	<common-header
		:type="'allow'"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">頭痛ーるスタンプ</h1>
	</common-header>
	<div class="stamp-container" id="js_stamp_container">
		<section class="stamp-present">
			<p class="stamp-intro-text">
				<span>4日間連続スタンププレゼント！</span>
				<br>
				いつも頭痛ーるをご利用いただいている皆様へ、頭痛－る「血液型あるある」スタンプをプレゼント！
				<br>
				※プレゼント期間：8月末まで
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div v-if="computedBsType === 1" class="stamp-download">
				<div class="stamp-download__wrapper">
					<p>1日目のスタンプ</p>
					<img src="@/assets/img/stamp/bloodtype202108_stamp01.png" alt="でたーA型 几帳面ですか…">
				</div>
			</div>
			<div v-else-if="computedBsType === 2" class="stamp-download">
				<div class="stamp-download__wrapper">
					<p>2日目のスタンプ</p>
					<img src="@/assets/img/stamp/bloodtype202108_stamp02.png" alt="でたーB型 またですか…">
				</div>
			</div>
			<div v-else-if="computedBsType === 3" class="stamp-download">
				<div class="stamp-download__wrapper">
					<p>3日目のスタンプ</p>
					<img src="@/assets/img/stamp/bloodtype202108_stamp03.png" alt="でたーO型 雑ですね…">
				</div>
			</div>
			<div v-else-if="computedBsType === 4" class="stamp-download">
				<div class="stamp-download__wrapper">
					<p>{{ changeStamp }}日目のスタンプ</p>
					<img v-if="changeStamp === 1" src="@/assets/img/stamp/bloodtype202108_stamp01.png" alt="でたーA型 几帳面ですか…">
					<img v-else-if="changeStamp === 2" src="@/assets/img/stamp/bloodtype202108_stamp02.png" alt="でたーB型 またですか…">
					<img v-else-if="changeStamp === 3" src="@/assets/img/stamp/bloodtype202108_stamp03.png" alt="でたーO型 雑ですね…">
					<img v-else src="@/assets/img/stamp/bloodtype202108_stamp04.png" alt="でたーAB型 さすがです…">
				</div>
			</div>
			<p v-if="computedBsType === 4" class="stamp-footer">
				4日間来てくれてありがとうニャ！
				<br>
				いままでの分のスタンプはこちらからダウンロードできるのでたくさんつかってみてほしいニャ！
			</p>
			<p v-else class="stamp-footer">
				明日も<span class="stamp-footer_orange">別の血液型スタンププレゼント</span>があるから、また遊びにきてね。
				<br>
				<span><span class="stamp-footer_red">マイページ登録</span>をすると簡単にアクセスできるよ！</span>
			</p>
			<div v-if="computedBsType === 4" class="stamp-links">
				<p @click="changeStamp = 1">A型あるあるスタンプ</p>
				<p @click="changeStamp = 2">B型あるあるスタンプ</p>
				<p @click="changeStamp = 3">O型あるあるスタンプ</p>
			</div>
			<!-- マイページ登録ボタン -->
			<div v-if="!isMyPage" class="sugotoku-lp__mypage">
				<div class="balloon__wrapper">
					<div class="balloon">
						<div class="balloon__inner">
							<div class="balloon__text--wrapper">
								<p class="balloon__text--title">とっても便利！</p>
								<p class="balloon__text">マイページ登録をすると、気圧予報や新着情報が毎日簡単にチェックできるよ&#9834;</p>
							</div>
							<div class="balloon__img--wrapper">
								<i class="sprite__mypage-button mypage-img" />
							</div>
						</div>
						<div class="balloon__close" @click="SET_IS_MY_PAGE(true)">
							<i class="sprite__mypage-button close-icon" />
						</div>
					</div>
				</div>
				<div :class="[
					'dcm_favorite_separateButton',
					'dcm_typeRed',
					'mypage__button',
					isLocalClass,
				]">
					<p>
						<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
					</p>
				</div>
			</div>
			<!-- スタンプの使い方 -->
			<div class="stamp-howtouse">
				<h2 class="stamp-howtouse__title">スタンプの使い方</h2>
				<div class="stamp-howtouse__inner">
					<h3 class="stamp-howtouse__subtitle">スタンプの保存方法</h3>
					<p class="stamp-howtouse__text">欲しいスタンプ画像を長押しして、端末に保存してください。</p>
					<h3 class="stamp-howtouse__subtitle">スタンプのご利用方法</h3>
					<p class="stamp-howtouse__text">1．ご利用のメッセンジャーアプリで、「画像選択」ボタンから、ダウンロードした画像を写真一覧から選択します。<br>
					2．送信ボタンをタップするとトーク画面に投稿されます。</p>
				</div>
				<!-- NOTE: アプリDLボタンエリア -->
				<div class="stamp-appdownload">
					<h3 class="stamp-appdownload__title">
						<img src="@/assets/img/stamp/stamp_appdownload_icon01.svg" class="stamp-appdownload__title--img" />
						<p class="stamp-appdownload__title--text">あなたの<span class="is-large">その不調、<br>低気圧のせい</span>かも!?</p>
					</h3>
					<div class="stamp-appdownload__symptoms">
						<img src="@/assets/img/stamp/stamp_appdownload_img01.png" alt="頭痛 落ち込み 肩こり だるさ むくみ" class="growthhack-f__symptoms--img">
					</div>
					<div class="about-rightnow__login">
						<p class="about-rightnow__login--text">頭痛ーるの気圧予報で<br>体調不良になりやすい日がわかるよ！</p>
						<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingStampAppDownloadAndroidBtn01">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
						<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingStampAppDownloadIosBtn01">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
					</div>
				</div>
				<div class="stamp-back" @click="$router.back()">TOPにもどる</div>
			</div>
		</section>
	</div>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA, SET_IS_MY_PAGE } from '../store/modules/common/mutation-types'

export default {
	name: 'StampBloodType',
	components: {
		CommonHeader
	},
	data () {
		return {
			isLocalClass: '',
			backendUrl: cmnConst.BACKEND_URL,
			changeStamp: 4
		}
	},
	computed: {
		...mapGetters('common', ['userId', 'userToken', 'userData', 'isMyPage', 'isAndroid']),

		// NOTE: urlパラメータまたはwebStorageからbs_typeを取得
		computedBsType () {
			if (this.$route.query.bs_type) {
				// URLパラメータがある場合
				return Number(this.$route.query.bs_type)
			} else {
				// URLパラメータがない場合
				if (this.userData) {
					if (this.userData.teigaku.web_storage) {
						const webStorage = JSON.parse(this.userData.teigaku.web_storage.data)
						// is_bs_dlが0の時に1に変更する
						if (Number(webStorage.is_bs_dl) === 0) this.updateIsBsDl()
						return webStorage.bs_type
					} else {
						return null
					}
				} else {
					return null
				}
			}
		}
	},
	mounted () {
		this.isLocalClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('common', ['updateWebStorage', SET_USER_DATA, SET_IS_MY_PAGE]),

		// NOTE: is_bs_dlが0の場合は1にする
		updateIsBsDl () {
			const webStorage = JSON.parse(this.userData.teigaku.web_storage.data)
			this.updateWebStorage({
				user_id: this.userId,
				user_token: this.userToken,
				data: {
					'bs_type': webStorage.bs_type,
					'is_bs_dl': 1
				}
			}).then(res => {
				// NOTE: userDataの更新
				return this.SET_USER_DATA({
					user_id: this.userId,
					user_token: this.userToken
				})
			}).catch(error => {
				// NOTE: エラーの処理
				console.log(error)
			})
		},

		// NOTE: マイページ登録ボタンクリック処理
		clickMypageButton (e) {
			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: 血液型スタンプページ限定の変数
$spacing-80: 5rem;
$stamp-title-text-color: #CA113A;
$stamp-footer-text-orange: #FE8F11;
$stamp-footer-text-red: #CA113A;

.stamp {
	&-container {
		overflow: hidden;
		padding-top: 44px;	// ヘッダーの高さ
		background: $background-primary;
	}
	&-intro-text {
		padding: 0 $spacing-10;
		span {
			margin: 0;
			text-align: left;
			font-size: 1.0625rem;
			font-weight: bold;
			color: $stamp-title-text-color;
		}
	}
	&-explanation {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 0 $spacing-10;
		&__text {
			$parent: &;
			box-sizing: border-box;
			display: inline-block;
			position: relative;
			background: $stamp-explanation-background;
			border-radius: 33px;
			width: 260px;
			margin: 0 0 0.875rem;
			padding: 0.875rem $spacing-20 $spacing-10 1.5625rem;
			&-emphasis {
				font-weight: bold;
			}
			&:before {
				content: '';
				position: absolute;
				border: 15px solid transparent;
				border-left: 36px solid $stamp-explanation-background;
				right: -30px;
				bottom: 3px;
				margin-top: -15px;
				transform: rotate(20deg);
			}
		}
		&__image {
			$parent: &;
			display: inline-block;
			width: 94px;
			height: 106px;
		}
	}
	&-download {
		background: $stamp-download-background;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-wrap: wrap;
		height: 69.34vw;
		margin-top: $spacing-10;
		padding: 2%;
		text-align: center;
		&__wrapper {
			width: 100%;
			p {
				width: 100%;
				font-size: 1.0625rem;
				font-family: $lp-family-tertiary;
				color: $text-seconday;
				margin: 0 0 $spacing-10 0;
			}
			img {
				max-width: 165px;
				width: 100%;
				border-radius: 10px;
				-webkit-touch-callout: default;
			}
		}
	}
	&-footer {
		padding: 0 $spacing-10;
		font-size: $font-size-14;
		margin: $spacing-10 0;
		&_orange {
			padding: 0;
			color: $stamp-footer-text-orange;
		}
		&_red {
			padding: 0;
			color: $stamp-footer-text-red;
		}
	}
	&-links {
		padding: 0 $spacing-10;
		margin-bottom: $spacing-10;
		p {
			margin: 0 0 $spacing-10;
			font-size: 1.0625rem;
			font-weight: bold;
			color: $stamp-footer-text-red;
			text-decoration: underline;
		}
		p:last-child {
			margin: 0;
		}
	}
	&-howtouse {
		margin-top: $spacing-20;
		&__title {
			font-size: $font-size-18;
			font-weight: bold;
			background: $stamp-howtouse-background;
			margin: 0;
			padding: $spacing-10;
		}
		&__inner {
			padding: $spacing-20 $spacing-10;
		}
		&__subtitle {
			font-size: $font-size-14;
			font-weight: bold;
			margin: 0;
		}
		&__text {
			margin-top: 0;
		}
	}
	&-appdownload {
		max-width: 375px;
		margin: 0 auto $spacing-20;
		color: #333;	// NOTE: 独自デザインによる指定
		&__title {
			width: 200px;
			margin: 0 auto;
			padding: 0 0 $spacing-8 0;
			border-bottom: solid 6px $primary;
			white-space: nowrap;
			&--img {
				display: block;
				margin: 0 auto;
			}
			&--text {
				margin: $spacing-4 0 0;
				text-align: center;
				font-size: $font-size-18;
				font-weight: bold;
				line-height: 1.35;	// NOTE: 独自デザインによる指定
				.is-large { font-size: 1.437rem; }	// NOTE: 独自デザインによる指定
			}
		}
		&__symptoms {
			width: 86%;	// w322.5 / w375
			max-width: 322.5px;
			margin: 1.875rem auto 0;	// NOTE: 独自デザインによる指定

			&--img { width: 100%; }
		}
		.about-rightnow__login { padding: 0 0 $spacing-20; }
	}
	&-back {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin: 0 auto;
		padding: 10px 10px 8px;
		text-align: center;
		text-decoration: none;
	}
}

</style>
